import {
    FamilyGoalFundingSummary,
    FamilyGoalFundingSummaryResponse,
    GoalFundingResponse,
} from "./models/Funding";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {findAllFamilyMembersFor} from "../../ClientManagement/FamilyTree/RelationshipTypeUtil";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {fundingApiClient} from "./FundingApiClient";

function transformBeneficiaryGoals(response: FamilyGoalFundingSummaryResponse, profile: ProfileResponse): FamilyGoalFundingSummary {
    const memberForGoal = findAllFamilyMembersFor(profile.primaryContact as MemberType)
        .find((member) => member.id === response.beneficiaryId)

    if (!memberForGoal) {
        throw new Error(`Unable to find family member with ID ${response.beneficiaryId}`);
    }

    return {
        ...response,
        name: `${memberForGoal?.firstName} ${memberForGoal?.lastName}`,
        birthdate: memberForGoal?.birthdate,
        age: memberForGoal?.age
    }
}

const processFamilyGoalFundingSummaryResponse = (familySummaryResponse: FamilyGoalFundingSummaryResponse[], profile: ProfileResponse)
                                                 :FamilyGoalFundingSummary[] => {
    let familySummary: FamilyGoalFundingSummary[] = [];
    familySummaryResponse.forEach((beneficiarySummaryResponse, index) => {
        familySummary[index] = transformBeneficiaryGoals(beneficiarySummaryResponse, profile)
    })
    return familySummary;
}

const getGoalFundingWithFamilyDetails = async (id:string, portfolioReserveTargetLength?: number): Promise<GoalFundingResponse> => {
    const profilePromise: Promise<ProfileResponse> = clientManagementApiClient.getProfile(id);
    let goalFundingPromise;
    if (portfolioReserveTargetLength != undefined) {
        goalFundingPromise = fundingApiClient.getGoalFundingOverTime(id, {
            selectedReserveTarget: String(portfolioReserveTargetLength)
        });
    } else {
        goalFundingPromise = fundingApiClient.getGoalFundingOverTime(id);
    }
    const [profile, goalFundingResponse] = await Promise.all([profilePromise, goalFundingPromise]);
    const familyGoalModel = goalFundingResponse['family'];
    const familySummaryResponse = familyGoalModel['goalSummary'];
    goalFundingResponse['family']['goalSummary'] = processFamilyGoalFundingSummaryResponse(familySummaryResponse, profile);
    return goalFundingResponse;
}


export default {getGoalFundingWithFamilyDetails};