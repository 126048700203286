import React, { ChangeEvent, useEffect, useState } from 'react';
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import FundingHeader from "./FundingHeader";
import Highcharts from "highcharts";
import {CreateFundingGraphOptions} from "./FundingGraphUtils";
import HighchartsReact from "highcharts-react-official";
import LoadingIndicator from 'src/pages/LoadingIndicator';
import { FundingDisplayOptions, GoalFundingResponse } from './models/Funding';
import FundingSummary from './FundingSummary';
import {DataDisplayView} from "../../ClientManagement/AssetAllocation/ReviseAssetAllocation";
import FundingAgeInput from './FundingAgeInput';
import { InvestorGroupMemberType, InvestorGroupType, emptyInvestorGroup } from 'src/ClientManagement/models/InvestorGroupType';
import { clientManagementApiClient } from 'src/ClientManagement/ClientManagementApiClient';
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";
import FundingService from "./FundingService";

const Funding = () => {
    const {id} = useParams<RouteWithId>();
    const { profile, approvedProfile } = useProfileAndProposals(id);
    const { displayName } = approvedProfile;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [fundingInfo, setFundingInfo] = useState<GoalFundingResponse>();
    const [fundingAge, setFundingAge] = useState<number>(0);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const [displayView, setDisplayView] = useState<DataDisplayView>(DataDisplayView.PERCENTAGE_VIEW);
    const [isFundingLoadedFirstTime, setIsFundingLoadedFirstTime] = useState<boolean>(true);
    const [displayOptions, setDisplayOptions] = useState<FundingDisplayOptions>(FundingDisplayOptions.ONLY_PORTFOLIO);

    const startAge = investorGroup.planningPeriod.ageFrom;
    const personalPlanningHorizon = investorGroup.planningPeriod.numberOfYears + investorGroup.planningPeriod.ageFrom;
    const clientDisplayName = investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PARTNER
        ? investorGroup.partnerMember?.name!
        : investorGroup.primaryMember?.name!;

    function loadGoalsFunding() {
        setIsLoading(true);
        Promise.all([
            FundingService.getGoalFundingWithFamilyDetails(id, profile.portfolioReserveTargetLength),
            clientManagementApiClient.getInvestorGroup(id)
        ]).then(([
            fundingResponse, investorGroupResponse ]) => {
            setFundingInfo(fundingResponse);
            setInvestorGroup(investorGroupResponse);
            setIsLoading(false);
        }).catch(error => console.error('Could not fetch funding details', error.message));
    }

    useEffect(() => {
        loadGoalsFunding();
    }, [id]);

    useEffect(() => {
        setFundingAge(startAge);
    }, [investorGroup])

    if (isLoading || !fundingInfo) {
        return <LoadingIndicator/>
    }

    const handleUpdateDataDisplay = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setDisplayView(value as DataDisplayView);
        setIsFundingLoadedFirstTime(true);
    }

    const graphOptions: Highcharts.Options = CreateFundingGraphOptions({
        displayName: displayName,
        fundingAge:fundingAge,
        startAge: startAge,
        maxAge:personalPlanningHorizon,
        fundingInfo: fundingInfo});

    return (
        <ScrollableContainer className="funding-page">
            <FundingHeader
                handleChangeDisplayView={handleUpdateDataDisplay}
                isFundingLoadedFirstTime={isFundingLoadedFirstTime}
                displayView={displayView}
                setDisplayOptions={setDisplayOptions}
            />
            <div className={`finding-charts-section`}>
                <HighchartsReact highcharts={Highcharts} options={graphOptions}/>
            </div>
            <FundingAgeInput
                fundingAtAge={fundingAge}
                setFundingAtAge={setFundingAge}
                clientDisplayName={clientDisplayName}
                minAge={startAge}
                maxAge={personalPlanningHorizon}
            />
            <FundingSummary
                ageIndex={fundingAge - startAge}
                fundingInfo={fundingInfo}
                displayOptions={displayOptions}
                displayView={displayView}
            />
        </ScrollableContainer>
    )
}

export default Funding