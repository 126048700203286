import React from 'react';
import {HeldAwayAccount, LegalAgreement} from "../../models/InvestmentProgram";
import {formatCurrency} from "../../../utils/format";
import {useAppSelector} from "../../../store/hooks";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";

type InvestmentProgramTableProps = {
    accounts: LegalAgreement[]
    heldAwayAccounts: HeldAwayAccount[]
}

const noValue = <span className="no-value">&ndash;&ndash;</span>;

const InvestmentProgramTable = ({accounts,heldAwayAccounts}: InvestmentProgramTableProps) => {
    const releaseToggles = useAppSelector(selectReleaseToggles);
    return (
        <div className="investment-program-table">
            <div className="investment-program-table-header investment-program-grid">
                <span>Legal Agreement Name</span>
                <span
                    className="textalign-right">Legal Agreement #</span>
                <span className="marginleft-xl textalign-left">Institution</span>
                <span className="textalign-right">As of Date</span>
                <span className="textalign-right">Market Value</span>
            </div>
            {accounts.map((account: LegalAgreement) => (
                <div
                    className="investment-program-table-row investment-program-grid"
                    key={account.name}
                >
                    <span className="truncate">{account.name}</span>
                    <span className="textalign-right">{account.legalAgreementNumber}</span>
                    <span className="marginleft-xl textalign-left">{account.institution}</span>
                    <span className="textalign-right">{account.asOfDate}</span>
                    <span className="textalign-right">
                        {account.marketValue !== null ? `${formatCurrency(account.marketValue)}` : noValue}
                    </span>
                </div>
            ))}

            {releaseToggles?.enableHeldAwayAccountForAsset &&
                <>
                    <div className="investment-program-table-header investment-program-grid">
                        <span>Held Away Account Name</span>
                        <span
                            className="textalign-right">Account #</span>
                        <span className="marginleft-xl textalign-left">Institution</span>
                        <span className="textalign-right">As of Date</span>
                        <span className="textalign-right">Market Value</span>
                    </div>
                    {heldAwayAccounts.map((heldAwayAccount: HeldAwayAccount) => (
                        <div
                            className="investment-program-table-row investment-program-grid"
                            key={heldAwayAccount.financialAccountName}
                        >
                            <span className="truncate">{heldAwayAccount.financialAccountName}</span>
                            <span className="textalign-right">{heldAwayAccount.financialAccountNumber}</span>
                            <span className="marginleft-xl textalign-left">{heldAwayAccount.institution}</span>
                            <span className="textalign-right">{heldAwayAccount.valueAsOfDate}</span>
                            <span className="textalign-right">
            {heldAwayAccount.baseMarketValue !== null ? `${formatCurrency(heldAwayAccount.baseMarketValue)}` : noValue}
                </span>
                        </div>
                    ))}
                </>
            }
        </div>
    );
};

export default InvestmentProgramTable;
