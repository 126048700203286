import {PortfolioReserveResponse} from "../../models/PortfolioReserve";
import {Button, PageActionMenu, Icon, RadioGroup, DropdownItem} from "../../../components";
import PortfolioRisk from "../PortfolioRisk";
import React, {ChangeEvent} from "react";
import {AssetTotals} from "../../../Assets/models/Assets";
import {DropdownGroup} from "xps-react";
import {NO_OP} from "../../../constants/common";
import {
    selectPortfolioReserveButtonStates,
     setPortfolioReserveButtonStates,
    setPortfolioReserveOptions, selectPortfolioReserveOptions
} from "../PortfolioReserveSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {MenuDivider} from "xps-react";

type HowRiskyRowProps = {
    portfolioRiskRowActive: number | undefined | boolean,
    onClickHowRiskInfoTip: () => void,
    editPortfolioReserve: PortfolioReserveResponse,
    assets: AssetTotals
}

export function HowRiskyRow({
                                portfolioRiskRowActive,
                                onClickHowRiskInfoTip,
                                editPortfolioReserve,
                                assets
                            }: HowRiskyRowProps) {

    const dispatch = useAppDispatch();
    const portfolioReserveOptions = useAppSelector(selectPortfolioReserveOptions);
    const buttonState = useAppSelector(selectPortfolioReserveButtonStates)
    const handleReserveOptionDropdown = (_e: ChangeEvent<HTMLInputElement>) => {
        let selectedPortfolioReserveOptions = {...portfolioReserveOptions}
        selectedPortfolioReserveOptions.showRiskImpact = !selectedPortfolioReserveOptions.showRiskImpact;

        dispatch(setPortfolioReserveOptions(selectedPortfolioReserveOptions));
    }
    const handleHideButton = () => {
         dispatch(setPortfolioReserveButtonStates({
             ...buttonState,
             portfolioRisk: false
         }))
    };
    return <>
        <div className="edit-portfolio-reserve-page__table__column">
            <div style={{display: "flex"}}>
                <div
                    style={{width: "95%"}}
                    data-testid="cvar-quick-slide-container"
                    className={`edit-portfolio-reserve-page__table__column__content ${portfolioRiskRowActive ? "" : "row-inactive"}`}>
                    How risky is this asset allocation?
                    <div className="edit-portfolio-reserve-page__table__column__subtext">
                        <span>Average of worst 5% of expected returns</span>
                        <Button
                            icon="only"
                            iconName="info_outline"
                            kind="borderless"
                            onClick={onClickHowRiskInfoTip}
                            size="small"
                        />
                    </div>
                </div>
                <PageActionMenu data-testid="market-stress-page-action" className={"pr-page-action-menu"}
                                panelWidth={200}>
                    <DropdownGroup groupName="Market Stress Tests" key={"ls-dropdown-group"} show={true}>
                        <DropdownItem className="reserve-page-action-menu-radio-options" value="" itemText="">
                            <RadioGroup
                                name=''
                                data-testid={"monte-carlo-options"}
                                layout="vertical"
                                values={["Conditional Value at Risk"]}
                                selected={"Conditional Value at Risk"}
                                disabled={false}
                            />
                        </DropdownItem>
                    </DropdownGroup>
                        <DropdownItem className="reserve-page-action-menu-options" value="" itemText="" onClick={NO_OP}>
                            Show Impact to Excess
                            <input
                                id="showRiskImpact"
                                name="show-risk-impact"
                                type="checkbox"
                                aria-label="show-risk-impact"
                                key={"show-risk-impact"}
                                style={{marginLeft: '10px'}}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleReserveOptionDropdown(e)
                                }}
                                checked={portfolioReserveOptions.showRiskImpact}
                            />
                        </DropdownItem>
                        {portfolioRiskRowActive &&
                        <MenuDivider
                            className="menu-divider"
                            role="separator"
                            itemText=""
                            value="do_not_use"
                            style={{
                                padding: "0",
                                margin: "8px -16px",
                                borderBottom: "1px solid lightgrey"
                            }}
                        />}
                        {portfolioRiskRowActive &&
                            <DropdownItem value="" itemText="" className="reserve-page-action-menu-options" onClick={handleHideButton} data-testid="hide-risk-button">
                                Hide this Section
                                <Icon ariaHidden  name="visibility_off_outline" style={{marginLeft: '52px'}} />
                            </DropdownItem>
                        }
                </PageActionMenu>
            </div>

        </div>
        <div
            className={`edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__second-column edit-portfolio-reserve-page__centered`}>
            <PortfolioRisk
                cvar={editPortfolioReserve.cvar.cvarPortfolioSupports}
                totalInvestableAssets={assets.totalInvestableValue}
                portfolioRiskRowActive={portfolioRiskRowActive}
            />
        </div>
        <div
            className={`edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__third-column edit-portfolio-reserve-page__centered`}>
            <PortfolioRisk
                cvar={editPortfolioReserve.cvar.cvarMinimumRisk}
                totalInvestableAssets={assets.totalInvestableValue}
                portfolioRiskRowActive={portfolioRiskRowActive}
            />
        </div>
    </>;
}