import {
    DataDisplayView,
    FamilyGoalFundingSummaryTotal,
    FundingDisplayOptions, FamilyGoalFundingSummary,
} from "./models/Funding";
import React from "react";
import {
    formatFundingCurrency,
    formatFundingPercentage,
    isDollarView, isShowFundingForInflows, isShowFundingForNonInvestable, isShowOnlyPortfolio
} from "./fundingUtils";
import {AccordionItem, Icon} from "../../components";
import {FundingAccordionItemHeader} from "./FundingAccordionItemHeader";
import GoalFundingTable from "./GoalFundingTable";


type FamilyFundingSummaryProps = {
    goalsSummaryTotal: FamilyGoalFundingSummaryTotal;
    ageIndex: number;
    title: string;
    color: string;
    displayOptions: FundingDisplayOptions;
    displayView: DataDisplayView;
}

const FamilyFundingSummary: React.FC<FamilyFundingSummaryProps> = ({
                                                                       goalsSummaryTotal,
                                                                       ageIndex,
                                                                       title,
                                                                       color,
                                                                       displayOptions,
                                                                       displayView
                                                                   }) => {
    const dollarView = isDollarView(displayView);
    return (
        <AccordionItem uuid={title}
                       accentColor={color}
                       HeaderComponent={({expanded, toggle}) =>
                           <FundingAccordionItemHeader
                               expanded={expanded}
                               toggle={toggle}
                               title={title}
                               fundedByRiskAssets={dollarView
                                   ? formatFundingCurrency(goalsSummaryTotal.fundedByRiskAssetsAtYear?.[ageIndex])
                                   : formatFundingPercentage(goalsSummaryTotal.fundedByRiskAssetsAtYearPercentage?.[ageIndex])}
                               fundedByRiskControl={dollarView
                                   ? formatFundingCurrency(goalsSummaryTotal.fundedByRiskControlAtYear?.[ageIndex])
                                   : formatFundingPercentage(goalsSummaryTotal.fundedByRiskControlAtYearPercentage?.[ageIndex])}
                               fundedByPortfolio={formatFundingCurrency(goalsSummaryTotal.fundedByPortfolioAtYear?.[ageIndex])}
                               fundedByNonInvestable={formatFundingCurrency(goalsSummaryTotal.fundedByNonInvestableAtYear?.[ageIndex])}
                               fundedByInflows={formatFundingCurrency(goalsSummaryTotal.fundedByInflowsAtYear?.[ageIndex])}
                               presentValue={formatFundingCurrency(goalsSummaryTotal.presentValueAtYear?.[ageIndex])}
                               gridClassName="funding-grid"
                               displayOptions={displayOptions}
                           />
                       }
        >
            <FamilyFundingTable
                goalsSummary={goalsSummaryTotal.goalSummary}
                ageIndex={ageIndex}
                displayOptions={displayOptions}
                dollarView={dollarView}
            />
        </AccordionItem>
    );
};

type FamilyFundingTableProps = {
    goalsSummary: FamilyGoalFundingSummary[];
    ageIndex: number;
    displayOptions: FundingDisplayOptions;
    dollarView: boolean;
}

const FamilyFundingTable: React.FC<FamilyFundingTableProps> = ({
                                                                   goalsSummary,
                                                                   ageIndex,
                                                                   dollarView,
                                                                   displayOptions,
                                                               }) => {
    return (
        <div className="family-summary-accordions" data-testid='family-summary-table-parent-div'>
            {goalsSummary.map((beneficiaryFundingGoal, index) => {
                if(!beneficiaryFundingGoal.goalItem.length) {
                    return <></>
                }
                return(
                    <ul key={`FamilyGoalAccordionItem${index}`}>
                        <AccordionItem
                            uuid={`FamilyGoalAccordionItem${index}`}
                            className="borderstyle-none"
                            HeaderComponent={({expanded}: { expanded: boolean }) => (
                                <div className="funding-grid funding-table-row"
                                     data-testid={`beneficiary-summary-row-${index}`} role="row">
                                    <div role="cell" className="paddingleft-md">
                                        <Icon name={expanded ? 'chevron_down' : 'chevron_right'}
                                              size="large"/>
                                        <span className="font-md paddingleft-md">
                                        {beneficiaryFundingGoal.name}, {beneficiaryFundingGoal.age}
                                    </span>
                                    </div>
                                    <span role="cell" className="font-md textalign-right"
                                          aria-label="Funded by Risk Assets">
                                    {dollarView
                                        ? formatFundingCurrency(beneficiaryFundingGoal.fundedByRiskAssetsAtYear?.[ageIndex])
                                        : formatFundingPercentage(beneficiaryFundingGoal.fundedByRiskAssetsAtYearPercentage?.[ageIndex])}
                                </span>
                                    <span role="cell" className="font-md textalign-right"
                                          aria-label="Funded by Risk Control">
                                    {dollarView
                                        ? formatFundingCurrency(beneficiaryFundingGoal.fundedByRiskControlAtYear?.[ageIndex])
                                        : formatFundingPercentage(beneficiaryFundingGoal.fundedByRiskControlAtYearPercentage?.[ageIndex])}
                                </span>
                                    {!isShowOnlyPortfolio(displayOptions) &&
                                        <span role="cell" className="font-md textalign-right"
                                              aria-label="Funded by Portfolio">
                                            {formatFundingCurrency(beneficiaryFundingGoal.fundedByPortfolioAtYear?.[ageIndex])}
                                        </span>}
                                    {isShowFundingForInflows(displayOptions) &&
                                        <span role="cell" className="font-md textalign-right"
                                              aria-label="Funded by Inflows">
                                            {formatFundingCurrency(beneficiaryFundingGoal.fundedByInflowsAtYear?.[ageIndex])}
                                        </span>}
                                    {isShowFundingForNonInvestable(displayOptions) &&
                                        <span role="cell" className="font-md textalign-right"
                                              aria-label="Funded by Non Investable">
                                        {formatFundingCurrency(beneficiaryFundingGoal.fundedByNonInvestableAtYear?.[ageIndex])}
                                        </span>}
                                    <span role="cell" className="font-md textalign-right"
                                          aria-label="Present Value">
                                    {formatFundingCurrency(beneficiaryFundingGoal.presentValueAtYear?.[ageIndex])}
                                </span>
                                </div>
                            )}
                        >
                            <GoalFundingTable
                                goals={beneficiaryFundingGoal.goalItem}
                                ageIndex={ageIndex}
                                goalType="Family Goals"
                                displayOptions={displayOptions}
                                dollarView={dollarView}
                            />
                        </AccordionItem>
                    </ul>
                )
            })}
        </div>
    )
};

export default FamilyFundingSummary;