import { ChangeEvent, useState, KeyboardEvent } from "react";
import ClickableYearsInput, { IconState } from "src/components/YearsInput/ClickableYearsInput";

export type FundingAgeInputProps = {
    fundingAtAge: number;
    setFundingAtAge: (updatedFundingAtAge: number) => void;
    clientDisplayName: string;
    minAge: number;
    maxAge: number;
}

function FundingAgeInput({
    fundingAtAge,
    setFundingAtAge,
    clientDisplayName,
    minAge,
    maxAge
}: FundingAgeInputProps) {
    const [age, setAge] = useState<number>(fundingAtAge);

    const clampAge = (updatedValue: number) => {
        if(updatedValue < minAge) {
            return minAge;
        } else if(updatedValue > maxAge) {
            return maxAge;
        } else {
            return updatedValue;
        }
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAge(Number(e.target.value));
    }

    const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
        const updatedValue = Number(e.target.value);
        const clampedValue = clampAge(updatedValue);
        setAge(clampedValue);
        setFundingAtAge(clampedValue);
    }

    const handleIncreaseYear = () => {
        setAge(fundingAtAge + 1);
        setFundingAtAge(fundingAtAge + 1);
    }

    const handleDecreaseYear = () => {
        setAge(fundingAtAge - 1);
        setFundingAtAge(fundingAtAge - 1);
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const clampedValue = clampAge(age);
            setAge(clampedValue);
            setFundingAtAge(clampedValue);
        }
    }

    return (
        <span className="funding-page__age-input-container font-xl">
            Proposed Goal Asset Allocation at &nbsp;
            <ClickableYearsInput
                name="fundingAtAge"
                onUpArrowClick={handleIncreaseYear}
                onDownArrowClick={handleDecreaseYear}
                onChangeValue={handleChange}
                onBlur={handleBlur}
                value={age}
                inputStyle={{
                    textAlign: "right",
                    paddingRight: "15%",
                }}
                inputClassName={"funding-page__age-input editable-age-border"}
                upArrowClassName={"dds-icons input__icon input__icon__increase-support-button"}
                downArrowClassName={"dds-icons input__icon input__icon__decrease-support-button"}
                disabledAndReadOnly={false}
                iconState={IconState.ARROWS}
                inlineLabel={"age"}
                inlineLabelAlign={"left"}
                disableUpArrowButton={fundingAtAge === maxAge}
                disableDownArrowButton={fundingAtAge === minAge}
                onKeyDown={handleKeyDown}
            />
            &nbsp; <span>({clientDisplayName})</span>
        </span>
    );
}

export default FundingAgeInput;