import React from "react";
import {Accordion, AccordionItem, Icon} from "../../../components";
import {InvestmentProgram} from "../../models/InvestmentProgram";
import {formatCurrency} from "../../../utils/format";
import InvestmentProgramSelection from "./InvestmentProgramSelection";
import InvestmentProgramTable from "./InvestmentProgramTable";
import {PaginationMobile} from "xps-react";
import LoadingIndicator from "../../../pages/LoadingIndicator";

type InvestmentProgramsProps = {
    investmentPrograms: InvestmentProgram[],
    displayProgramManager: boolean,
    handleSave: (investmentProgram: InvestmentProgram) => void,
    currentPage: number,
    setCurrentPage: (currentPage: number) => void,
    pageSize: number,
    setPageSize: (pageSize: number) => void,
    totalPages: number,
    totalInvestmentPrograms: number,
    isLoading?: boolean
}

export default function InvestmentPrograms({
                                               investmentPrograms,
                                               displayProgramManager,
                                               handleSave,
                                               currentPage,
                                               setCurrentPage,
                                               pageSize,
                                               setPageSize,
                                               totalPages,
                                               totalInvestmentPrograms,
                                               isLoading
                                           }: InvestmentProgramsProps) {
    if (isLoading) {
        return <LoadingIndicator />;
    }
    return <Accordion
        allowMultipleExpanded={false}
        className="grid-display-contents"
    >
        <div className="investment-program-header investment-program-header-grid">
            <span className="paddingleft-xxl">Name</span>
            {displayProgramManager ? <span>Portfolio Manager</span> : <span></span>}
            <span className="textalign-right">Status</span>
            <span className="textalign-right">Last Updated</span>
            <span className="textalign-right marginright-md">Market Value</span>
        </div>
        {investmentPrograms.map((investmentProgram) => {
                const investmentProgramAccounts = investmentProgram.legalAgreements;
                const investmentProgramHeldAwayAccounts = investmentProgram.heldAwayAccounts;

                return <AccordionItem
                    uuid={investmentProgram.id}
                    key={investmentProgram.id}
                    HeaderComponent={({expanded}) => (
                        <div className="investment-program-accordion-header investment-program-header-grid">
                                            <span className="paddingleft-md display-flex align-items-center">
                                              <Icon name={expanded ? 'chevron_down' : 'chevron_right'} size="large"/>
                                              <span className="paddingleft-md">
                                                  <div
                                                      className="investment-program-name margin-none cell-text-content">
                                                          {investmentProgram.name}
                                                      </div>
                                              </span>
                                            </span>

                            {displayProgramManager ?
                                <span
                                    className="investment-program-header-column investment-program-portfolio-manager cell-text-content">
                                    {investmentProgram.portfolioManagerName}
                                </span> : <span></span>
                            }
                            <span
                                className="investment-program-header-column textalign-right">{investmentProgram.status}</span>
                            <span
                                className="investment-program-header-column textalign-right">{investmentProgram.lastUpdatedDate}</span>
                            <span
                                className="investment-program-header-column marginright-md textalign-right">{formatCurrency(investmentProgram.totalMarketValue)}</span>
                        </div>
                    )}
                >
                    <div className="investment-program-expanded-view paddingbottom-md">
                        <div className="investment-program-tables">
                            { (investmentProgramAccounts.length || investmentProgramHeldAwayAccounts.length)&&
                                <InvestmentProgramTable
                                    accounts={investmentProgramAccounts}
                                    heldAwayAccounts={investmentProgramHeldAwayAccounts}
                                />}
                        </div>
                        <div className="investment-program-footer margin-xl">
                            <InvestmentProgramSelection investmentProgram={investmentProgram}
                                                        handleSave={handleSave}/>
                        </div>
                    </div>
                </AccordionItem>;
            }
        )}

        <div
            className="total-investment-programs-count paddingleft-xxl paddingtop-md paddingbottom-md fontweight-600 font--normal">
            Total Investment Programs &nbsp;&nbsp;&nbsp; {totalInvestmentPrograms}
        </div>

        {investmentPrograms && investmentPrograms.length > 0 &&
            <div className="investment-program-pagination margintop-lg">
                <PaginationMobile id="InvestmentProgramTable"
                            canNext={currentPage < totalPages - 1}
                            canPrevious={currentPage != 0}
                            pageText={"Page"}
                            ofText="of"
                            pageSize={pageSize}
                            page={currentPage}
                            pageSizeDropUp={true}
                            pageSizeOptions={[15, 25, 50, 100]}
                            pages={totalPages}
                            showPageInfo={true}
                            showPageJump={true}
                            showPageSizeOptions={true}
                            onPageChange={(page: number) => setCurrentPage(page)}
                            onPageSizeChange={(size: number) => setPageSize(size)}
                />
            </div>
        }
    </Accordion>;
}
