import React, {useEffect, useState} from "react";
import {Tab, TabBar, TabPanel, TabsProvider} from "../../components";
import {PartnerDashboardProfile} from "../models/ProfileResponse";
import {clientManagementApiClient} from "../ClientManagementApiClient";
import {setHeaderTickerTabs} from "../ClientProfile/HeaderTickerTabsSlice";
import {EMPTY_HEADER_TICKER_TABS_RESPONSE} from "../ClientProfile/HeaderTickerTabType";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import PartnerDashboard from "./PartnerDashboard";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {RouteWithProfileType} from "../../routes/types";
import {ClientType} from "../constants";
import ResetScreenModal from "../../components/ResetScreenModal";
import {Button, Modal} from "xps-react";

const DashboardParent = () => {
    const dispatch = useAppDispatch();

    const [clientProfiles, setClientProfiles] = useState([] as PartnerDashboardProfile[])
    const [isLoading, setIsLoading] = useState(true)

    const releaseToggles = useAppSelector(selectReleaseToggles);
    const {type} = useParams<RouteWithProfileType>();
    const history = useHistory();
    const location = useLocation();
    usePageViewTimer('Partner Dashboard Page Load Timer (milliseconds)', isLoading);
    const [showUnlinkedModal, setShowUnlinkedModal] = useState<boolean>(false)

    function handleUnlinkedModal()  {
        setShowUnlinkedModal(!showUnlinkedModal)
    }

    useEffect(() => {
        dispatch(setHeaderTickerTabs(EMPTY_HEADER_TICKER_TABS_RESPONSE));
        clientManagementApiClient
            .getPartnerDashboardProfiles()
            .then((p) => {
                setIsLoading(false);
                setClientProfiles(p.data);
            }).catch(error => console.error('Could not fetch partner dashboard profiles', error.message))
    }, [])

    const getLastSelectedTab = () => {
        if (type?.toLowerCase() === ClientType.PROSPECT) {
            return 1;
        }
        else  if (type?.toLowerCase() === ClientType.SAMPLE) {
            return 2;
        }else {
            return 0;
        }
    }

    return <div className="display-flex margin-xl">
        <section className="partner-dashboard-section">
            <div className="partner-dashboard-page">
                <Modal
                    header="No Linked Client Partner Workstation (PW) Profile"
                    label="modal-label"
                    id={'no-linked-client-pw'}
                    size='small'
                    isOpen={showUnlinkedModal}
                    showCloseButton={false}
                    showFooter={true}
                    onRequestClose={() => setShowUnlinkedModal(false)}
                    footer={
                        <Button
                        kind={'primary'}
                        onClick={() => setShowUnlinkedModal(false)}
                        size="medium">
                            Okay
                        </Button>
                    }
                ><span className={"no-linked-client-modal"}>To provide access to the Service Team, a PW Client Profile must be linked via Profile Settings.</span>
                </Modal>
                <TabsProvider tabIdArray={["clientsTab", "prospectsTab", "samplesTab"]} defaultActiveIndex={getLastSelectedTab()}>
                    {releaseToggles?.enableProspectProfiles && releaseToggles?.enableSampleTab &&
                        <TabBar size="medium" theme="dark">
                            <Tab className="clients-tab" name="Clients" onClick={() => {
                                history.replace('/Dashboard/Client')
                            }}/>
                            <Tab name="Prospects" onClick={() => {
                                history.replace('/Dashboard/Prospect')
                            }}/>
                            <Tab name="Samples" onClick={() => {
                                history.replace('/Dashboard/Samples')
                            }}/>
                        </TabBar>
                    }
                    {releaseToggles?.enableProspectProfiles && !releaseToggles?.enableSampleTab &&
                        <TabBar size="medium" theme="dark">
                            <Tab className="clients-tab" name="Clients" onClick={() => {
                                history.replace('/Dashboard/Client')
                            }}/>
                            <Tab name="Prospects" onClick={() => {
                                history.replace('/Dashboard/Prospect')
                            }}/>
                        </TabBar>
                    }
                    {!releaseToggles?.enableProspectProfiles && releaseToggles?.enableSampleTab &&
                        <TabBar size="medium" theme="dark">
                            <Tab className="clients-tab" name="Clients" onClick={() => {
                                history.replace('/Dashboard/Client')
                            }}/>
                            <Tab name="Samples" onClick={() => {
                                history.replace('/Dashboard/Sample')
                            }}/>
                        </TabBar>
                    }
                    {!releaseToggles?.enableProspectProfiles && !releaseToggles?.enableSampleTab &&
                        <TabBar size="medium" theme="dark">
                            <Tab className="clients-tab" name="Clients" onClick={() => {
                                history.replace('/Dashboard/Client')
                            }}/>
                        </TabBar>
                    }
                    <TabPanel>
                        <PartnerDashboard profiles={clientProfiles.filter(profile => !profile.prospect && !profile.sample)}
                                          isEnableProfileAssistant={releaseToggles?.enableProfileAssistant ?? false}
                                          isLoading={isLoading}
                                          tabType={ClientType.CLIENT}
                                          handleUnlinkedModal={handleUnlinkedModal}
                        />
                        <PartnerDashboard profiles={clientProfiles.filter(profile => profile.prospect)}
                                          isEnableProfileAssistant={false}
                                          isLoading={isLoading}
                                          tabType={ClientType.PROSPECT}
                                          handleUnlinkedModal={handleUnlinkedModal}
                        />
                        <PartnerDashboard profiles={clientProfiles.filter(profile => profile.sample)} // should filter for sample profiles
                                          isEnableProfileAssistant={false}
                                          isLoading={isLoading}
                                          tabType={ClientType.SAMPLE}
                                          handleUnlinkedModal={handleUnlinkedModal}
                        />
                    </TabPanel>
                </TabsProvider>
            </div>
        </section>
        {location.pathname === '/' && <ResetScreenModal />}
    </div>
}

export default DashboardParent;